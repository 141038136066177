<template>
  <tr
    class="tasks-table-row"
    :class="{'failed': taskFailed}">
    <td>
      <b-checkbox
        v-model="localSelectedTasks"
        :nativeValue="task.id"
        class="checkbox-only"/>
    </td>
    <td>
      <a @click="onClickTask">
        {{ task.orderIdentifier }}
      </a>
    </td>
    <td v-if="!isCompleted">
      <b-taglist>
        <task-status :task="task"/>
        <b-tag
          v-if="task.isPickup"
          type="is-dark">
          {{ $t('tasks.table.task.pickupLabel') }}
        </b-tag>
      </b-taglist>
    </td>
    <td>
      {{ task.rangeLabel }}
      <template v-if="isSearch">
        <br>
        {{ formattedDate }}
      </template>
    </td>
    <td>
      <div class="courier-name has-text-weight-bold">
        {{ courierName }}
      </div>
      <div class="courier-contact">
        {{ courierContact }}
      </div>
    </td>
    <td class="fulfilment-address">
      {{ task.deliveryAddress || task.pickupPointName }}
    </td>
    <td>
      <div class="user-contact">
        {{ task.customerName }}
        •
        {{ task.customerContactNumber }}
      </div>
      <div class="user-email">
        {{ task.customerEmail }}
      </div>
    </td>
    <td v-if="isCompleted">
      <template v-if="task.completedAt">
        {{ formatTimeFromDate(task.completedAt) }}
      </template>
      <task-status
        v-else
        :task="task"/>
    </td>
  </tr>
</template>

<script>
import { formatDate, formatTimeFromDate, isSystemAccount } from '@js/utils'
import { TASK_STATES } from '@js/constants'

const TaskStatus = () => import('@components/TaskStatus')

export default {
  name: 'tasks-table-row',
  components: {
    TaskStatus
  },
  props: {
    task: {
      type: Object,
      required: true
    },
    selectedTasks: {
      type: Array,
      required: true
    },
    isCompleted: {
      type: Boolean,
      default: false
    },
    isSearch: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    taskFailed() {
      return this.task.state === TASK_STATES.FAILED
    },
    localSelectedTasks: {
      get() {
        return this.selectedTasks
      },
      set(value) {
        this.$emit('update:selectedTasks', value)
      }
    },
    formattedDate() {
      return formatDate(this.task.servingDate, {
        yearFirst: false,
        separator: '/'
      })
    },
    courier() {
      const trip = this.task.trip
      if (trip) {
        return trip.fulfilmentUser
      }
      return null
    },
    courierName() {
      return this.courier && this.courier.name
    },
    courierContact() {
      if (this.courier && !isSystemAccount(this.courier)) {
        return this.courier.mobileNumber
      }
      return null
    }
  },
  methods: {
    onClickTask() {
      this.$router.push({
        name: 'tasks',
        params: {
          id: this.task.orderIdentifier
        }
      })
    },
    formatTimeFromDate(date) {
      return formatTimeFromDate(date)
    }
  }
}
</script>

<style lang="scss" scoped>
.tasks-table-row {
  &.failed {
    background-color: $danger-light;
  }

  td.fulfilment-address {
    max-width: 200px;
  }
}
</style>
